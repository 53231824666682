<template>
  <article class="event_reservation">
    <div class="box_title main">
      <h2 class="title">EVENT HALL</h2>
      <p class="description">{{$t('content.event.EventReservation.txt01')}}</p>
    </div>
    <section class="report_group">
<!--      <MobileBaseTab-->
<!--        v-model="tabValue"-->
<!--        :list="multiRoom"-->
<!--        @input="tabCliek">-->
<!--      </MobileBaseTab>-->
      <div class="tab_list">
        <div class="tab_wrap" role="tablist" ref="track">
          <a href="javascript:void(0);"
             v-for="(tab, index) in multiRoom"
             :key="index"
             class="tab"
             ref="tabs"
             role="tab"
             :aria-selected="index === tabValue ? 'true' : ''"
             @click="Hander(index)"
          >{{tab}}</a>
        </div>
      </div>
      <div class="tab_contents" role="tabpanel">
        <div class="report_box hall_swiper">
          <!-- 이미지 슬라이더 -->
          <MobileBaseSlider
            ref="swiperSlider"
            :list="multiRoomList"
            :autoplay='false'
            :navigation="false"
            :pagination="true"
            @change="onChangeMultiRoom"
          >
            <template v-slot="{ item }">
              <img :src="item.img" class="img_fit" :alt="`${item.title} 이미지`" />
            </template>
          </MobileBaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
        <!-- 이미지 한장일 경우 -->
<!--        <div class="report_box hall_swiper">-->
<!--          <div class="swiper-slide">-->
<!--            <img :src="multiRoomList[0].img" class="img_fit" :alt="$t('content.event.EventReservation.txt02')" />-->
<!--          </div>-->
<!--        </div>-->
        <!-- //이미지 한장일 경우 -->
        <MobileBaseTab
          v-model="infoTab"
          :list="[$t('content.event.MobileEventReservation.txt10'), $t('content.event.MobileEventReservation.txt11')]"
          class="line expend">
        </MobileBaseTab>
        <div class="report_box tab_contents" role="tabpanel">
          <!-- 이용안내 -->
          <div class="info-area" v-html="multiRoomList[tabValue].content" v-show="infoTab === 0"></div>
          <!-- // 이용 안내 -->

          <!-- 이용방법 -->
          <div class="rent-area" v-show="infoTab === 1">
            <div class="rent-info">
              <strong class="title">{{$t('content.event.EventReservation.txt17')}}</strong>
              <p class="comment">
                {{$t('content.event.EventReservation.txt140')}}
              </p>
              <ol class="guide-list">
                <li>
                  <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
                 <MobileToggle
                   :isShow="false">
                   <template slot="title">
                     <span class="icon" aria-hidden="true">1</span>
                     <strong class="title" name="title">{{$t('content.event.EventReservation.txt18')}}</strong>
                     <p class="des">{{$t('content.event.EventReservation.txt141')}}</p>
                   </template>
                   <template slot="content">
                     <div v-html="$t('content.event.EventReservation.txt19')"></div>
                   </template>
                 </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">2</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt20')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt142')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt21')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">3</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt22')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt143')}}</p>
                    </template>
                    <template slot="content">
                      {{$t('content.event.EventReservation.txt23')}}
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">4</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt24')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt144')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt25')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">5</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt26')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt145')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt27')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">6</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt28')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt146')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt29')"></div>
                    </template>
                  </MobileToggle>
                </li>
              </ol>
            </div>
            <!-- 주의 사항 -->
            <div class="tline caution-info">
              <div class="title-wrap">
                <strong class="title">
                  <i class="i_noti"></i>
                  <!--꼭 확인 부탁드립니다-->
                  {{$t('content.event.EventReservation.txt30')}}
                </strong>
                <p class="desc-text">
                  {{$t('content.event.EventReservation.txt31')}}
                  <a href="#" class="underline-text" @click.prevent="onFacilitiesPopup">
                    <strong>{{$t('content.event.EventReservation.txt32')}}</strong>
                  </a> {{$t('content.event.EventReservation.txt33')}}
                </p>
              </div>
              <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt34')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt35')}}</li>
                    <li>
                      {{$t('content.event.EventReservation.txt36')}}
                      <br />
                      <span class="space">{{$t('content.event.EventReservation.txt37')}}</span>
                    </li>
                  </ul>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt38')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt39')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt40')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt41')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt42')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt43')}}</li>
                    <li>{{$t('content.event.EventReservation.txt44')}}</li>
                    <li>{{$t('content.event.EventReservation.txt45')}}</li>
                    <li v-html="$t('content.event.EventReservation.txt46')"></li>
                    <li>{{$t('content.event.EventReservation.txt47')}}</li>
                  </ul>
                  <div class="reference-mark">
                    <span>{{$t('content.event.EventReservation.txt50')}}</span>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt51')}}</li>
                      <li>{{$t('content.event.EventReservation.txt52')}}</li>
                      <li>{{$t('content.event.EventReservation.txt53')}}</li>
                    </ul>
                  </div>
                </template>
              </MobileToggle>
            </div>
            <!-- // 주의 사항 -->
            <!-- 대관 신청 문의 -->
            <div class="tline call-info">
              <strong class="title">{{$t('content.event.EventReservation.txt54')}}</strong>
              <dl class="label_cont">
                <dt class="label">{{$t('content.event.EventReservation.txt56')}}</dt>
                <dd class="cont">
                  <ExternalLink to="mailto:leesuok@hanwha.com"><span> leesuok@hanwha.com</span>
                  </ExternalLink>
                  <p>02-520-1513</p>
                </dd>
              </dl>
            </div>
            <!-- // 대관 신청 문의 -->
          </div>
          <!-- //이용방법 -->
        </div>
      </div>
    </section>
    <!-- 버튼 -->
    <div class="btn_bottom_wrap">
      <button class="btn-basic" @click.prevent="goNext">
        {{$t('content.event.EventReservation.txt59')}}
      </button>
    </div>
    <!-- // 버튼 -->
  </article>
</template>

<script>
import Intro from '@/views/reservation/Intro.vue';
import MobileBaseSlider from '@/components/slider/MobileBaseSlider.vue';
import MobileToggle from '@/components/base/MobileToggle.vue';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';

export default {
  name: 'MobileReservation',
  extends: Intro,
  components: {
    MobileBaseSlider,
    MobileToggle,
    MobileBaseTab,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      offsetArray: [],
      totalWidth: 0,
      infoTab: 0,
      tabValue: 0,
      multiRoomList: [
        {
          img: this.requireAssetsImage('event/MainHall_01.jpg'),
          title: 'Main Hall',
          content: this.$t('content.event.MobileEventReservation.txt1'),
        },
        {
          img: this.requireAssetsImage('event/Multi_A_01.jpg'),
          title: 'Multi Room A',
          content: this.$t('content.event.MobileEventReservation.txt2'),
        },
        {
          img: this.requireAssetsImage('event/Multi_B_01.jpg'),
          title: 'Multi Room B',
          content: this.$t('content.event.MobileEventReservation.txt3'),
        },
        {
          img: this.requireAssetsImage('event/Multi_C_01.jpg'),
          title: 'Multi Room C',
          content: this.$t('content.event.MobileEventReservation.txt4'),
        },
        {
          img: this.requireAssetsImage('event/Multi_BC_01.jpg'),
          title: 'Multi Room B+C',
          content: this.$t('content.event.MobileEventReservation.txt5'),
        },
        {
          img: this.requireAssetsImage('event/Multi_D_01.jpg'),
          title: 'Multi Room D',
          content: this.$t('content.event.MobileEventReservation.txt6'),
        },
        {
          img: this.requireAssetsImage('event/Multi_E_01.jpg'),
          title: 'Multi Room E',
          content: this.$t('content.event.MobileEventReservation.txt7'),
        },
        {
          img: this.requireAssetsImage('event/Multi_DE_01.jpg'),
          title: 'Multi Room D+E',
          content: this.$t('content.event.MobileEventReservation.txt8'),
        },
      ],
    };
  },
  mounted() {
    this.$refs.tabs.forEach((e, i) => {
      this.offsetArray[i] = e.offsetLeft;
    });
    this.totalWidth = this.$refs.track.clientWidth;
    this.moveScroll(this.tabValue);
  },
  computed: {
    multiRoom() {
      return this.multiRoomList.reduce((all, item) => all.concat(item.title), []);
    },
  },
  methods: {
    moveScroll(idx) {
      const $thisLi = this.$refs.tabs[idx];
      const offsetLeft = this.offsetArray[idx];
      let posX = offsetLeft;
      if (idx > 0) {
        posX = offsetLeft - ((this.totalWidth / 2) - ($thisLi.clientWidth / 2));
      } else {
        posX = 0;
      }
      this.$refs.track.scrollLeft = posX;
    },
    Hander(event) {
      // const index = Array.from(event.currentTarget.children).indexOf(event.target);
      const index = event;
      for (let i = 0; i < this.$refs.track.children.length; i += 1) {
        if (index === i) {
          this.tabCliek(i);
        }
      }
      this.moveScroll(index);
      this.tabCliek(index);
    },
    onChangeMultiRoom(v) {
      this.tabValue = v;
      this.moveScroll(v);
    },
    tabCliek(i) {
      this.tabValue = i;
      this.$refs.swiperSlider.$el.swiper.slideTo(i + 1);
    },
    goNext() {
      const userInfo = this.$store.getters.getUserInfo();
      if (userInfo) {
        this.$router.push({ path: '/reservation/application' });
      } else {
        this.goLoginPage()
      }
    },
    goLoginPage() {
      const buttons = [{
        title: this.$t('content.event.Event.txt11'),
        type: 'line',
        class: '',
        handler: () => {
          this.$modal.hide('dialog');
        },
      }, {
        title: this.$t('content.event.Event.txt12'),
        default: true,
        class: '',
        handler: () => {
          this.$router.push({
            path: '/reservation/application'
          });
        },
      }];
      this.showConfirm(this.$t('confirmMessage.requiredLogin'), {
        buttons: buttons
      });
    }
  },
};
</script>

<style scoped>
.event-reservation-wrap .info-area {
    width: initial;
}
</style>
