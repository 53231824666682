<template>
  <div class="card_group" :class="cardShow ? 'active': '' ">
    <a href="javascript:void(0)" class="card_header"
       role=”button”
       @click="cardShow = !cardShow">
      <slot name="title"></slot>
    </a>
    <transition name="fade">
      <div class="card_panel">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 기본 Mobile Toggle 컴포넌트
 */
export default {
  name: 'MobileToggle',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardShow: this.isShow,
    };
  },
};
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
